<template>
  <div>
    <HomePageTop></HomePageTop>
    <Dialog ref="dialog" :config="config"></Dialog>
    <div class="main">
      <div class="type">
        <button :class="{ isbtn: type == 0 }">
          <!-- 购物车 -->{{ $fanyi("购物车") }}
        </button>
      </div>
      <div class="head">
        <ul>
          <li class="chexk-box" style="font-size: 14px">
            <input
              type="checkbox"
              name=""
              id=""
              v-model="checked"
              @change="allChoice"
            />
            <span>{{ $fanyi("全选") }}</span>
          </li>
          <li class="goods"><!-- 产品 -->{{ $fanyi("产品") }}</li>
          <li class="detail"><!-- 详情 -->{{ $fanyi("详情") }}</li>
          <li><!-- 单价 -->{{ $fanyi("单价") }}</li>
          <li><!-- 数量 -->{{ $fanyi("数量") }}</li>
          <li><!-- 总价 -->{{ $fanyi("总价") }}</li>
          <li><!-- 操作 -->{{ $fanyi("操作") }}</li>
        </ul>
      </div>
      <!-- 购物车有商品 -->
      <div class="lists" v-if="lists != false">
        <div class="item" v-for="(item, index) in lists" :key="index">
          <h4>
            <input
              type="checkbox"
              name=""
              id=""
              v-model="item.checked"
              @change="shopAll(index)"
            />
            {{ $fanyi("店铺") }} ：<span
              style="cursor: pointer;"
              @click="
                $fun.routerToPage(
                  `shopGoods?shopName=${item.shop_name_translate ||
                    item.shop_name}&shopId=${item.shop_id}`
                )
              "
              >{{ item.shop_name_translate || item.shop_name }}</span
            >
            <a href="javascript:;" @click="$fun.routerToPage('/')">{{
              item.goods_title
            }}</a>
            <img
              src="../../assets/img/index2_143.png"
              alt=""
              v-if="item.type == '1688'"
            />
          </h4>
          <!-- 单个商品 -->
          <div class="list">
            <ul v-for="(listItem, listIndex) in item.list" :key="listIndex">
              <li class="checkBox">
                <div class="check">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="listItem.checked"
                    @change="oddChoice(index)"
                  />
                </div>
                <div class="img">
                  <a
                    href="javascript:;"
                    @click="
                      $fun.routerToPage(
                        '/ProductDetails?goods_id=' + listItem.goods_id,
                        true
                      )
                    "
                  >
                    <el-popover placement="right" trigger="hover">
                      <img
                        :src="listItem.pic"
                        alt=""
                        style="width: 300px; height: 300px"
                      />
                      <img :src="listItem.pic" alt="" slot="reference" />
                    </el-popover>
                  </a>
                </div>
              </li>

              <li class="goods">
                <div class="text">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <p>
                      <a
                        href="javascript:;"
                        @click="
                          $fun.routerToPage(
                            '/ProductDetails?goods_id=' + listItem.goods_id,
                            true
                          )
                        "
                        >{{ listItem.goods_title }}
                      </a>
                    </p>
                    <p :title="listItem.goods_title" slot="reference">
                      <a
                        href="javascript:;"
                        @click="
                          $fun.routerToPage(
                            '/ProductDetails?goods_id=' + listItem.goods_id,
                            true
                          )
                        "
                        style="font-weight: 400"
                        >{{ listItem.goods_title }}
                      </a>
                    </p>
                  </el-popover>
                  <input
                    class="bei"
                    type="text"
                    :placeholder="$fanyi('备注栏')"
                    v-model="listItem.user_postscript"
                    @blur="editCartRemark(listItem)"
                  />
                </div>
              </li>
              <!-- 商品详情 -->
              <li class="detail">
                <el-popover placement="bottom" trigger="hover">
                  <div class="goodsDetailAll">
                    <div
                      class="goodsDetailAllOneBox"
                      v-for="(detailItem, detailIndex) in listItem.detail"
                      :key="detailIndex"
                      :title="detailItem.key + ':' + detailItem.value"
                    >
                      <h1 class="detailTitle">{{ detailItem.key }}</h1>
                      <p>
                        <span class="detailBody">{{ detailItem.value }}</span>
                      </p>
                    </div>
                  </div>
                  <div slot="reference" class="DetailAll">
                    <p
                      v-for="(detailItem, detailIndex) in listItem.detail"
                      :key="detailIndex"
                      style="font-weight: 400"
                      :title="detailItem.key + ':' + detailItem.value"
                    >
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>
                  </div>
                </el-popover>
              </li>
              <li class="price">
                <div class="price-box">
                  <!-- 单价 -->
                  <!--2023/2/8 11:40: 经讨论购物车单价不随数量变动,因为如果需要匹配数据量过大并且订单详情等单价数据都需要变动,会有很多错误 -->
                  <div class="danjia">
                    <b>
                      <!-- {{ Number(listItem.price) }} -->
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.ceil(
                            (listItem.univalence && listItem.univalence != 0
                              ? listItem.univalence
                              : Number(listItem.price)) *
                              $store.state.exchangeRate
                          )
                        )
                      }}€({{
                        $fun.RMBNumSegmentation(
                          listItem.univalence && listItem.univalence != 0
                            ? listItem.univalence
                            : Number(listItem.price)
                        )
                      }}￥)
                    </b>
                  </div>
                </div>
              </li>
              <!-- 选择数量 -->
              <li style="text-align: center" class="setNum">
                <!-- :max="listItem.kuCun" -->
                <el-input-number
                  v-model="listItem.num"
                  :step="1"
                  step-strictly
                  size="mini"
                  :min="1"
                  @change="numAmend(index, listIndex, listItem)"
                ></el-input-number>
              </li>
              <!-- 总价 -->
              <li style="text-align: center">
                <span
                  >{{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(listItem.total * $store.state.exchangeRate)
                    )
                  }}€({{ $fun.RMBNumSegmentation(listItem.total) }}¥)</span
                >
              </li>
              <li style="text-align: center">
                <a
                  href="javascript:;"
                  class="del"
                  @click="delCart(listItem.cart_id, index, listIndex)"
                >
                  <img :src="require('@/assets/icon/delete.png')" alt="" />
                </a>
              </li>
            </ul>
            <div class="fg"></div>
          </div>
        </div>
        <div class="loadingBox" v-if="!finish">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img :src="require('@/assets/icon/cart.png')" alt="" />
          <div class="nav">
            <p style="margin-bottom: 30px; font-size: 24px">
              {{ $fanyi("购物车为空") }}
            </p>
            <p>
              <a href="javascript:;" @click="$fun.routerToPage('/')"
                >{{ $fanyi("回到顶部") }} |</a
              >
              <a
                href="javascript:;"
                @click="$fun.routerToPage('/user/collection')"
              >
                {{ $fanyi("你的最爱") }} |</a
              >
              <a to="#" @click="$fun.routerToPage('/user/commodity')"
                >{{ $fanyi("资料库") }}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class="foot">
        <input
          type="checkbox"
          name=""
          id=""
          v-model="checked"
          @change="allChoice"
        /><b class="quanXuan">{{ $fanyi("全选") }}</b>
        <b @click="delCart(false)" class="del">
          <img :src="require('@/assets/icon/delete.png')" alt="" />
        </b>
        <div class="total">
          <div class="goodsNum">
            <p>
              {{ $fanyi("商品种类") }}： <span>{{ idArr.length }}</span>
            </p>
            <p>
              {{ $fanyi("数量总计") }}： <span>{{ isTotal }}</span>
            </p>
          </div>
        </div>
        <div class="total">
          <p>
            <!-- 总价: -->{{ $fanyi("总价") }}：
            <span>
              <span class="price"
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(totalPrice * $store.state.exchangeRate)
                  )
                }} </span
              >EUR
            </span>
          </p>
          <p>
            <span class="RMBTotal">
              {{ $fun.RMBNumSegmentation($fun.ceil(totalPrice)) }} RMB
            </span>
          </p>
        </div>
        <button class="btn" @click="jieSuan">
          {{ $fanyi("结算") }}
        </button>
      </div>
    </div>
    <!-- 购物车页面购买数量过低提醒 -->
    <purchaseQuantityTooLowReminder ref="purchaseQuantityTooLowReminderRef" />
    <!-- 推荐商品 -->
    <recommendCommodities
      v-if="!(lists != false)"
      style="margin: -40px auto 47px auto"
    />
    <FootVue />
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop";
import Dialog from "../../components/public/Dialog";
import FootVue from "../../components/foot/Foot.vue";
import recommendCommodities from "../../components/homePage/recommendCommodities.vue";
import purchaseQuantityTooLowReminder from "./components/cart/purchaseQuantityTooLowReminder.vue";
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      checked: true, //全选
      type: 0, // 类型
      num: 0, //
      isTotal: 0, // 选中的商品数
      shopTotal: 0, // 选中的店铺
      totalPrice: 0, // 选中的商品总价
      lists: [], // 数据
      idArr: [], // 选中的ID
      page: 0,
      pageSize: 20,
      // 弹窗的属性
      config: {
        width: "480px",
        title: this.$fanyi("删除提示"),
        btnTxt: [this.$fanyi("删除(购物车)"), this.$fanyi("取消")],
      },

      canload: true,
      finish: false, //加载完成
    };
  },
  computed: {},
  components: {
    HomePageTop,
    Dialog,
    FootVue,
    recommendCommodities,
    purchaseQuantityTooLowReminder,
  },
  created() {
    this.getCartList();

    document.addEventListener("scroll", this.scrollBox);
  },
  methods: {
    scrollBox() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let offsetHeight = document.documentElement.offsetHeight;
      if (offsetHeight < scrollTop + 2000) {
        this.getCartList();
      }
    },
    toHome() {
      sessionStorage.setItem("toHot", true);
      this.$fun.routerToPage("/");
    },
    // 判断显示那个价格区间
    panduanxianshi(num, arr) {
      if (num < arr[0].startQuantity) {
        return 0;
      }
      let s = 0;
      arr.forEach((item, index) => {
        if (num >= arr[index].startQuantity) {
          s = index;
        }
      });
      return s;
    },
    // 订单购物车获取数据
    getCartFrom() {
      let ids = [];
      this.$parent.newList.forEach((item) => {
        ids.push(item.id);
      });
      let datas = {
        token: this.token,
        ids: ids.join(","),
      };
    },
    // 获取数据
    getCartList() {
      if (this.canload && !this.finish) {
        this.canload = false;
        this.page++;
        this.$api
          .goodsCartList({
            only_list: "yes",
            page: this.page,
            pageSize: this.pageSize,
          })
          .then((res) => {
            this.canload = true;
            if (res.code != 0) {
              this.lists = [];
              this.finish = true;
              return false;
            }
            if (res.data.length < this.pageSize) {
              this.finish = true;
            }
            res.data.forEach((element) => {
              this.lists.push(element);
            });
            this.tidyData();
            // 2023/4/13 购物车打开时需要全部选中
            if (this.$route.name == "cart") {
              this.allChoice();
            }
          });
      }
    },
    // 整理数据
    tidyData() {
      this.lists.forEach((item) => {
        item.type = item.list[0].type; // 店铺类别
        item.list.forEach((item1) => {
          // 商品总数
          item1.checked = false; // 商品选择
          item1.univalence = 0; // 显示单价
          item1.total = 0;

          let detailStraight = ((detail) => {
            let str = [];
            detail.forEach((detailitem) => {
              str.push(detailitem.value);
            });
            //console.log(str);
            return '"' + str.join("㊖㊎") + '"';
          })(item1.detail);

          if (item1.goods_inventory[detailStraight]) {
            item1.kuCun = item1.goods_inventory[detailStraight].amountOnSale;
          } else {
            item1.kuCun = 1;
          }

          // item1.price_range = JSON.parse(item1.price); // 字符串转换组数
        });
      });
      this.subtotal();
    },
    // 修改数量
    numAmend(i, index, item) {
      //   数量不能小于最小数
      if (item.num < 1) {
        this.lists[i].list[index].num = item.price_range[0].startQuantity;
        return this.$message.error(this.$fanyi("数量不能小于最小数"));
      }
      this.subtotal();
      let datas = {
        num: item.num,
        cart_id: item.cart_id,
        price: item.univalence == 0 ? item.price : item.univalence,
      };
      this.$forceUpdate();
      this.$api.goodsCartEditNum(datas).then((res) => {
        if (res.code != 0) return;
        this.commodityTotal();
      });
    },
    // 总价
    subtotal() {
      //console.log(this.lists);
      this.lists.forEach((item) => {
        item.list.forEach((listItem) => {
          //2023/4/14 因为价格混乱所以暂时取消根据数量匹配价格
          // if (listItem.price_range.length == 1) {
          //   if (!isNaN(listItem.price_range[0])) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[0]
          //     );
          //     listItem.univalence = listItem.price_range[0];
          //   } else {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[0].price
          //     );
          //     listItem.univalence = listItem.price_range[0].price;
          //   }
          // } else if (listItem.price_range.length == 2) {
          //   if (listItem.num < listItem.price_range[1].startQuantity) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[0].price
          //     );
          //     listItem.univalence = listItem.price_range[0].price;
          //   } else if (listItem.num >= listItem.price_range[1].startQuantity) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[1].price
          //     );
          //     listItem.univalence = listItem.price_range[1].price;
          //   }
          // } else if (listItem.price_range.length == 3) {
          //   if (listItem.num < listItem.price_range[1].startQuantity) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[0].price
          //     );
          //     listItem.univalence = listItem.price_range[0].price;
          //   } else if (
          //     listItem.num >= listItem.price_range[1].startQuantity &&
          //     listItem.num < listItem.price_range[2].startQuantity
          //   ) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[1].price
          //     );
          //     listItem.univalence = listItem.price_range[1].price;
          //   } else if (listItem.num >= listItem.price_range[2].startQuantity) {
          //     listItem.total = this.$fun.ceil(
          //       listItem.num * listItem.price_range[2].price
          //     );
          //     listItem.univalence = listItem.price_range[2].price;
          //   }
          // }
          if (!listItem.univalence || listItem.univalence == 0) {
            listItem.total = this.$fun.ceil(listItem.num * listItem.price);
          }
        });
      });
    },

    // 单选
    oddChoice(i) {
      let flag = this.lists[i].list.every((item) => item.checked == true);
      if (flag) {
        this.lists[i].checked = true;
      } else {
        this.lists[i].checked = false;
      }
      this.isCheckedAll();
      this.commodityTotal();
      this.$forceUpdate();
      //   flag ? (this.lists[i].checked = true) : (this.lists[i].checked = false);
    },
    // 店铺全选
    shopAll(i) {
      if (this.lists[i].checked) {
        this.lists[i].list.forEach((item) => {
          item.checked = true;
        });
      } else {
        this.lists[i].list.forEach((item) => {
          item.checked = false;
        });
      }
      this.$forceUpdate();
      this.isCheckedAll();
      this.commodityTotal();
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true;
          this.shopAll(index);
        });
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false;
          this.shopAll(index);
        });
      }
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0;
      this.isTotal = 0;
      this.shopTotal = 0;
      this.idArr = [];
      this.lists.forEach((item, i) => {
        if (item.list.some((items) => items.checked)) {
          this.shopTotal += 1;
        }
        item.list.forEach((listItem, j) => {
          if (listItem.checked) {
            this.totalPrice += listItem.total - 0;
            this.isTotal += listItem.num;
            this.idArr.push(listItem.cart_id);
          }
        });
      });
      //   //console.log(this.totalPrice, this.isTotal, this.idArr);
    },
    // 删除
    delCart(id, shopIndex, goodsIndex) {
      let datas = {
        cart_id: "",
      };
      let msg = this.$fanyi("将该商品将移出购物车");
      if (id) {
        datas.cart_id = id;
      } else {
        if (this.idArr == false)
          return this.$message.error(this.$fanyi("未选择操作的商品"));
        datas.cart_id = this.idArr.join(",");

        msg = this.$fanyi("是否将选中的商品移除购物车");
      }
      this.$refs.dialog.open(
        // 文本,复杂的在组件里添加插槽
        msg,
        () => {
          // 确认按钮事件
          this.$api.goodsCartDelete(datas).then((res) => {
            if (res.code != 0) return;
            this.$message({
              type: "success",
              message: this.$fanyi("移除成功"),
            });
            // this.getCartList();
            // this.tidyData();
            // for (let i = 0; i < this.lists.length; i++) {}
            if (id) {
              this.lists[shopIndex].list.splice(goodsIndex, 1);
              if (this.lists[shopIndex].list == 0) {
                this.lists.splice(shopIndex, 1);
              }
            } else {
              // 将购物车选中的选项删除
              for (let i = 0; i < this.lists.length; i++) {
                let shopItem = this.lists[i];
                for (let j = 0; j < this.lists[i].list.length; j++) {
                  let goodsItem = this.lists[i].list[j];
                  if (this.idArr.indexOf(goodsItem.cart_id) != -1) {
                    shopItem.list.splice(j, 1);
                    j--;
                  }
                }
                if (shopItem.list.length == 0) {
                  this.lists.splice(i, 1);
                  i--;
                }
              }
            }
            // 获取总价
            this.commodityTotal();
            // 重新计算购物车商品数量
            this.$store.dispatch("goodsToCartNum");
            // }
          });
        },
        () => {}
      );
    },

    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == "") return false;
      let datas = {
        cart_id: item.cart_id,
        user_postscript: item.user_postscript,
      };
      this.$api.goodsCartPostscript(datas).then((res) => {
        if (res.code != 0) return false;
      });
    },
    // 结算
    jieSuan() {
      if (this.idArr.length > 60) {
        return this.$message.warning(this.$fanyi("一个订单最多添加60个商品"));
      }
      if (!this.idArr.length) {
        return this.$message.warning(this.$fanyi("至少选择一个产品"));
      }
      // 如果商品总购买数量超过100个就直接跳转到订单详情页面,否则弹出提示弹窗
      if (this.isTotal < 100) {
        this.$refs.purchaseQuantityTooLowReminderRef.open(() => {
          this.goToOrderDetailPage();
        });
      } else {
        this.goToOrderDetailPage();
      }
    },
    // 跳转到订单详情页面
    goToOrderDetailPage() {
      let id = this.idArr.join(",");
      this.$fun.routerToPage("/OrderDetails?id=" + id + "&type=temporary");
    },
    // 追加到购物车
    zhuijia() {
      let ids = this.idArr.join(",").split(",");
      // 根据路由上有没有id属性来判断是提出订单还是临时保存订单，临时保存订单需要循环数据获取商品id，提出订单获取路由上的id
      if (this.$route.query.id) {
        var nowIds = this.$route.query.id.split(",");
      } else {
        var nowIds = [];
        this.$parent.$parent.newList.forEach((item) => {
          item.list.forEach((etem) => {
            nowIds.push(etem.id);
          });
        });
        //console.log(nowIds);
      }
      let new_arr = this.$fun.quChu(ids, nowIds);
      if (new_arr.length == 0) return (this.$parent.$parent.showCart = false);
      new_arr = new_arr.join(",");
      this.$parent.$parent.addTo(new_arr);
      this.$parent.$parent.showCart = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/mixin";
.main {
  width: $pageWidth;
  margin: 10px auto 80px;
  background: #fff;
  box-shadow: 0 0 10px 0rgba (61, 61, 61, 0.1);
  border-radius: 6px;
  padding: 20px 0 0;
  border: 1px solid #e8e8e8;
  &.hei {
    height: 450px;
    overflow: auto;
  }
  input {
    vertical-align: middle;
  }
  .type {
    button {
      font-size: 18px;
      font-weight: 600;
      color: #000;
      background-color: transparent;
      line-height: 10px;
      letter-spacing: 0px;
      margin: 20px 0 38px 20px;
    }
  }
  input {
    width: 22px;
    height: 22px;
  }
  .head {
    // top的设置在行内样式里
    height: 56px;
    background-color: #eee;
    margin: 0 20px;
    position: sticky;
    z-index: 4;
    top: 108px;

    ul {
      display: flex;
      line-height: 56px;
      font-size: 15px;
      font-weight: 600;
      .chexk-box {
        text-align: left;
        display: flex;
        padding-left: 10px;
        align-items: center;
        input {
          margin-right: 10px;
        }
      }
      .goods {
        flex: 0 0 300px;
      }

      li {
        flex: 1;
        text-align: center;

        input {
          margin-left: 10px;
        }
        &.detail {
          flex: 0 0 200px;
        }
      }
    }
  }
  .lists {
    margin: 0 20px;
    padding-bottom: 20px;
    .loadingBox {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 30px;
        color: #757575;
      }
    }
  }
  .item {
    h4 {
      font-size: 14px;
      line-height: 64px;
      display: flex;
      align-items: center;
      input {
        margin: 0 20px;
      }
      a {
        color: #333;
        &:hover {
          color: #999;
        }
      }
      img {
        margin-left: 5px;
        vertical-align: middle;
        float: none;
      }
    }
    .list {
      .fg {
        width: 1360px;
        height: 10px;
        background: #ededed;
        margin: 0 auto;
      }
      ul {
        height: 150px;
        display: flex;
        padding-top: 20px;
        border-top: 1px solid #ededed;
        .checkBox {
          input {
            margin-left: 20px;
          }
        }
        .goods {
          width: 300px;
          .text {
            height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > input {
              padding-left: 10px;
            }
          }
        }
        li {
          // width: 14.28%;
          flex: 1;
          .check {
            height: 80px;
            display: flex;
            align-items: center;
          }
          input {
          }
          &:first-child {
            display: flex;
            margin-right: 10px;
            .img {
              float: left;
              width: 75px;
              height: 75px;
              margin-left: 15px;
              border-radius: 8px;
              overflow: hidden;
              img {
                width: 75px;
                height: 75px;
              }
            }
          }
          &.detail {
            position: relative;
            flex: 0 0 200px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
          }
          &.goods {
            position: relative;
            // > div {
            //   position: absolute;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   text-align: left;
            //   font-size: 14px;
            //   color: #000000;
            //   line-height: 19px;
            //
            // }
          }
          a {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .bei {
            width: 300px;
            height: 30px;
            border-radius: 15px;
            border: 1px solid rgba($color: #182280, $alpha: 0.3);
          }
          span {
            color: #ffa018;
            font-weight: 700;
          }
        }
        .del {
          &:hover {
            opacity: 0.3;
          }
        }
        .price {
          .price-box {
            .danjia {
              padding: 0 25px;
              text-align: center;
            }
            p {
              text-align: center;
              padding: 0 10px;
            }
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .cart {
    padding: 50px 0;
    text-align: center;
    overflow: hidden;
    > div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    img {
      margin: 0 auto;
      width: 64px;
      height: 64px;
    }
    .nav {
      margin: 26px 0 30px;
      p {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        a {
          color: $homePageSubjectColor;
          font-size: 20px;
        }
      }
    }
  }
  .foot {
    width: 1400px;
    height: 100px;
    background: #fff;
    padding-right: 20px;
    box-shadow: 0 0 10px 0rgba (61, 61, 61, 0.1);
    border-radius: 6px;
    position: sticky;
    bottom: -0px;
    left: 50%;
    // transform: translate(-50%);
    line-height: 50px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;
    border: 1px solid #e8e8e8;
    input {
      margin-left: 21px;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    b {
      cursor: pointer;
      font-size: 14px;

      color: #888888;
      line-height: 19px;
      &.quanXuan {
        font-weight: 400;
      }
      &.del {
        font-size: 14px;
        font-weight: 400;
        color: $homePageSubjectColor;
        line-height: 19px;
        margin-left: 40px;
      }
    }
    .total {
      margin: 0 0 0 auto;
      font-weight: 12px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-left: 0px;
      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;
        .price {
          color: #ffa018;
          font-weight: bold;
          font-size: 24px;
          max-width: 246px;
          // flex-direction: column;

          span {
            font-size: 24px;
          }
        }
        .RMBTotal {
          font-weight: 400;
          font-size: 14px;
        }
      }
      span {
        font-size: 14px;
        font-weight: 700;
        // margin: 0 10px;
      }
      .goodsNum {
        margin: 0 0 0 250px;
        position: relative;
        line-height: 30px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-left: 50px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    i {
      margin: 0 20px;
    }
    button {
      width: 250px;
      height: 60px;
      background: #47bd9b;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
      /*   */
      cursor: not-allowed;
      margin-left: 20px;
    }
    .btn {
      background-color: #5bb578;
      cursor: pointer;
    }
  }
  .order {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
.DetailAll {
  padding: 0 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  max-height: 90%;
  // border: solid;
  width: 100%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-align: center;
  p {
    margin: 0 auto;
    // width: 190px;
    text-align: left;
  }
}
</style>
